.dayItem {
    padding: 3px;
    display: inline-block;
    width: 14%;
    box-sizing: border-box;

}
.another {
    opacity: .2;
}
.currentDate {
    background: rgba(0, 0, 255, 0.18);
}
.dailyTasks {
    padding: 5px 0;
}

.mtlabel {
    margin-top: -22px;
}
.pie2 {
    width: 300px;
    /*height: 300px;*/
}
.pie {
    width: 200px;
}

.line2  {
    margin-top: 2px;
}
.lineDay  {
    WIDTH: 100%;
    height: 3px;
    min-width: 5px;
    border-radius: 5px;
    background: grey;
}

.o2 {
    opacity: .2;
}

.planCircleItem {
    width: 33%;
    box-sizing: border-box;
    display: inline-block;
}