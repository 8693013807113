blockquote {
    border-left: 5px solid darkgreen!important;
    padding-left: 10px!important;
    margin-left: 10px!important;
    padding-top: 2px!important;
    padding-bottom: 2px!important;
    margin-bottom: 2px!important;
}

.boldListItem {
    padding: 3px 0;
}

.bookActive {background: rgba(203, 235, 245, 0.3);}
.grouppingItems, .grouppingItems * {
    font-size: 18px!important;
}