
.titleName {
    font-size: 24px;
    font-weight: 500;
}
.titleWrap {
    width: 100%;
    float: right;
}
.titleWrap:hover .titleDesc {
    max-height: 100%!important;
    overflow: none;
}
.titleDesc {
    font-size: 12px;
    max-height: 55px;
    overflow: hidden;

}

.linkCat {
    font-size: 12px;
    font-weight: bold;
    margin-top: 3px;
}

.ml-5 {
    margin-left: 5px;
}

.authorsList {
    display: inline-block;


}
.authorsItem:hover {
    text-decoration: none;
}
.authorsItem {
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: 12px;
    text-decoration: none;
    padding: 3px;
    text-align: center;
    margin-left: 5px;
    margin-top: 0px;
    margin-bottom: 2px;
    font-weight: bold;
    box-shadow: 1px 1px 5px rgba(128, 128, 128, 0.44);
}
[data-desc-size="superShort"] .titleWrap {
    margin-bottom: 5px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5px;
}
[data-desc-size="superShort"] .woShort {
    display: none;
}
[data-desc-size="noDesc"] .titleDesc, [data-desc-size="superShort"] .titleDesc {
    height: 0!important;
    overflow:hidden!important;

}
[data-desc-size="desc"] .titleDesc {
    height: auto!important;
    max-height: 100% !important;
    overflow:none!important;

}